import React, { useEffect, useState, useContext, useRef } from "react";
import { Row, Col, Button, message, Form, Input } from "antd";
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import Helper from "../../extra/helper";
import "antd/dist/antd.css";
import moment from "moment";
import Service from "./../../extra/getAPI";
import Ifile from "./../../assets/images/salesManager/ifile.png";
import Idowload from "./../../assets/images/salesManager/taixuong2.png";
import Export from "./../../components/table-excel/quan-ly-don/excel-chi-tiet-don";
import IXoa from "./../../assets/images/salesManager/trash-02.png";
import MdCancelOder from "./../../components/modal/quan-ly-don/mdCancelOrder";
import { exportTableToExcel } from "./../../components/library/export";
import { GlobalState } from "./index";
import { AuthContext } from "./../../context/AuthProvider";

export default function Thongtindonhang() {
  const { setPage, detail, renderStatus, setDetail } = useContext(GlobalState);
  const { setIndexPage, checkAccountGC } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [openCancelOrder, setOpenCancelOrder] = useState(false);
  const [link, setLink] = useState("");
  const [form] = Form.useForm();
  const formRef = React.createRef();
  const importInputRef = useRef();
  //Id Check đóng mở quyền lợi bảo hiểm
  const [selectedIds, setSelectedIds] = useState([]);
  //Danh sách người được bảo hiểm
  const [insuredPersons, setInsuredPersons] = useState([]);

  useEffect(() => {
    if (detail) {
      // Hỗ trợ những đơn V2 mua cho nhiều người
      if (detail.insured_persons.length > 0) {
        setInsuredPersons([...detail.insured_persons]);
      } else {
        setInsuredPersons([detail.insured_persons])
      }
      if (detail?.note) {
        form.setFieldsValue({ 'note': detail?.note })
      }
    }
  }, [detail]);

  useEffect(() => {
    window.scrollTo(0, 0);
    // setIndexPage(0);
    form.setFieldsValue({ cernum: detail?.cert_num })
  }, []);

  const onFinish = (val) => {
    form
      .validateFields()
      .then(async (values) => {
        UpdateCer(detail?.uid, values);
      })
      .catch((err) => { });
  };

  // Cập nhật ghi chú của CSKH
  const onFinish2 = (val) => {
    form
      .validateFields()
      .then(async (values) => {
        Note(values.note);
      })
      .catch((err) => { });
  };

  const exportFile = async () => {
    setTimeout(() => {
      exportTableToExcel("report-detail", `chi-tiet-don.xlsx`);
    }, 1000);
  };

  useEffect(() => {
    if (link) {
      form.setFieldsValue({ cer_link: link });
    }
  }, [link]);

  const handleImportFile = (files) => {
    message.warning("Tài liệu đang được xử lý. Vui lòng chờ trong giây lát.");
    uploadG2(files, detail?.product_by_provider_product_title);
  };

  // Úp hình và file
  const uploadG2 = (file, productName) => {
    setLoading(true);
    var form = new FormData();
    form.append("storage", "s3");
    form.append("file", file);
    form.append(
      "path",
      `/products/certificate/${productName}/${moment().format("YYYY-MM-DD")}`
    );
    Service.apicall_upload_G2("POST", `/upload`, form)
      .then((res) => {
        setLoading(false);
        let data = res?.data;
        if (data?.complete) {
          setLink(data.link);
        } else {
          setLink("");
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  // Lấy chi tiết đơn hàng
  const GetDetail = (uid) => {
    setLoading(true);
    Service.apicall("GET", `/api/v1/products-hub/health/portal/order/${uid}`)
      .then((res) => {
        if (res.data.statusCode === 200) {
          setDetail(res?.data?.result);
          form.setFieldsValue({ cernum: res?.data?.result?.cert_num })
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  // Từ chối đơn hàng
  const Reject = (content) => {
    setLoading(true);
    const data = {
      reason: content,
    };
    Service.apicall(
      "PUT",
      `/api/v1/products-hub/health/portal/order/${detail?.uid}/reject`,
      data
    )
      .then((res) => {
        if (res.data.statusCode === 200) {
          GetDetail(detail?.uid);
          handleCancel();
          message.success("Huỷ đơn thành công!");
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  // Huỷ đơn hàng
  const CustomerReject = (content) => {
    setLoading(true);
    const data = {
      reason: content,
    };
    Service.apicall(
      "PUT",
      `/api/v1/products-hub/health/portal/order/${detail?.uid}/customer-reject`,
      data
    )
      .then((res) => {
        if (res.data.statusCode === 200) {
          GetDetail(detail?.uid);
          handleCancel();
          message.success("Từ chối đơn thành công!");
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  // Ghi chú CSKH
  const Note = (content) => {
    setLoading(true);
    const data = {
      note: content,
    };
    Service.apicall(
      "PUT",
      `/api/v1/products-hub/health/portal/order/${detail?.uid}/note`,
      data
    )
      .then((res) => {
        if (res.data.statusCode === 200) {
          GetDetail(detail?.uid);
          handleCancel();
          message.success("Ghi chú thành công!");
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  // Cập nhật link giấy và số giấy chứng nhận
  const UpdateCer = (uid, value) => {
    let data = {
      cert_num: value?.cernum,
      cert_link: value?.cer_link,
    };
    setLoading(true);
    Service.apicall(
      "PUT",
      `/api/v1/products-hub/health/portal/order/${uid}?status=3`,
      data
    )
      .then((res) => {
        setLoading(false);
        if (res.data.statusCode === 200) {
          message.success("Cập nhật thành công!");
          GetDetail(uid);
        } else {
          message.error("Cập nhật thất bại!");
        }
      })
      .catch((err) => {
        setLoading(false);
        message.error("Cập nhật thất bại!");
      });
  };

  const handleCancel = () => {
    setOpenCancelOrder(false);
  };

  //Lấy UID đóng mở quyền lợi bảo hiểm
  const toggleSelection = (id) => {
    setSelectedIds((prevSelectedIds) =>
      prevSelectedIds.includes(id)
        ? prevSelectedIds.filter((itemId) => itemId !== id)
        : [...prevSelectedIds, id]
    );
  };

  return (
    <>
      {openCancelOrder && (
        <MdCancelOder
          loading={loading}
          open={true}
          handleCancel={handleCancel}
          reject={detail?.status === 8 ? Reject : CustomerReject}
          status={detail?.status}
        />
      )}
      <Export data={[detail]} />
      <div style={{ marginBottom: 10, fontWeight: 600, fontSize: 20 }}>
        Thông tin đơn
      </div>
      <Row gutter={[15, 20]}>
        <Col xl={17} xs={24}>
          <Row style={{ overflow: "hidden" }}>
            <div
              style={{
                background: "#ffffff",
                width: "100%",
                padding: 20,
                borderRadius: 2,
                color: "#4B4B4B",
              }}
            >
              <Row gutter={[10, 0]}>
                <Col xs={24}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 10,
                    }}
                  >
                    <div
                      style={{
                        fontSize: 18,
                        fontWeight: 600,
                        marginBottom: 10,
                      }}
                    >
                      Thông tin bảo hiểm
                    </div>
                    <div>
                      <Button
                        onClick={() => exportFile()}
                        style={{
                          padding: "0px 20px",
                          borderRadius: 5,
                          width: "fit-content",
                          display: "flex",
                          alignItems: "center",
                          fontWeight: 500,
                          height: 32,
                          border: "1px solid #3961A0",
                          color: "#3961A0",
                        }}
                      >
                        <img
                          src={Idowload}
                          style={{
                            width: 13,
                            marginRight: 5,
                          }}
                        />
                        Tải xuống
                      </Button>
                    </div>
                  </div>
                </Col>
                <Col xl={4} xs={12}>
                  <div style={{ fontSize: 13 }}>Loại bảo hiểm</div>
                  <div>
                    <b>{detail?.product_title}</b>
                  </div>
                </Col>
                <Col xl={5} xs={12}>
                  <div style={{ fontSize: 13 }}>Tên sản phẩm</div>
                  <div>
                    <b>{detail?.product_by_provider_product_title}</b>
                  </div>
                </Col>
                <Col xl={4} xs={12}>
                  <div style={{ fontSize: 13 }}>Gói bảo hiểm</div>
                  <div>
                    <b>
                      {detail?.packages?.title || detail?.packages?.[0]?.title}
                    </b>
                  </div>
                </Col>
                <Col xl={5} xs={12}>
                  <div style={{ fontSize: 13 }}>Công ty bảo hiểm</div>
                  <div>
                    <b>
                      {detail?.product_by_provider_provider_title ||
                        detail?.provider_title}
                    </b>
                  </div>
                </Col>
                <Col xl={6} xs={12}>
                  <div style={{ fontSize: 13 }}>Thời hạn BH</div>
                  <div>
                    <b>
                      {Helper._formatDayNoHour(detail?.begin_date)} -{" "}
                      {Helper._formatDayNoHour(detail?.end_date)}
                    </b>
                  </div>
                </Col>
              </Row>
            </div>
            {/* <div
              style={{
                background: "#ffffff",
                width: "100%",
                padding: 20,
                borderRadius: 2,
                color: "#4B4B4B",
                marginTop: 15,
              }}
            >
              <Row gutter={[10, 0]}>
                <Col xs={24}>
                  <div>
                    Gói bảo hiểm{" "}
                    <b>
                      {detail?.packages?.title || detail?.packages?.[0]?.title}
                    </b>
                  </div>
                </Col>
              </Row>
            </div> */}
            <div
              style={{
                background: "#ffffff",
                width: "100%",
                padding: 20,
                borderRadius: 2,
                color: "#4B4B4B",
                marginTop: 15,
              }}
            >
              <Row gutter={[10, 0]}>
                <Col xs={24}>
                  <div
                    style={{
                      fontSize: 18,
                      fontWeight: 600,
                      marginBottom: 10,
                    }}
                  >
                    Thông tin chủ hợp đồng
                  </div>
                </Col>
                <Col xl={6} xs={12}>
                  <p>
                    <div style={{ fontSize: 13 }}>Chủ hợp đồng</div>
                    <div style={{ textTransform: "capitalize" }}>
                      <b>{detail?.buyer_name}</b>
                    </div>
                  </p>
                  <p>
                    <div style={{ fontSize: 13 }}>Số điện thoại</div>
                    <div>
                      <b>{detail?.buyer_phone}</b>
                    </div>
                  </p>
                </Col>
                <Col xl={6} xs={12}>
                  <p>
                    <div style={{ fontSize: 13 }}>Ngày sinh</div>
                    <div>
                      <b>{Helper._formatDayNoHour(detail?.buyer_dob)}</b>
                    </div>
                  </p>
                  <p>
                    <div style={{ fontSize: 13 }}>Email</div>
                    <div
                      style={{ whiteSpace: "normal", wordWrap: "break-word" }}
                    >
                      <b>{detail?.buyer_email}</b>
                    </div>
                  </p>
                </Col>
                <Col xl={6} xs={12}>
                  <p>
                    <div style={{ fontSize: 13 }}>Giới tính</div>
                    <div>
                      <b>{+detail?.buyer_gender === 1 ? "Nam" : "Nữ"}</b>
                    </div>
                  </p>
                  <p>
                    <div style={{ fontSize: 13 }}>Địa chỉ</div>
                    <div>
                      <b>{detail?.buyer_address}</b>
                    </div>
                  </p>
                </Col>
                <Col xl={6} xs={12}>
                  <p>
                    <div style={{ fontSize: 13 }}>CCCD/ Mã định danh</div>
                    <div>
                      <b>{detail?.buyer_private_id}</b>
                    </div>
                  </p>
                </Col>
                {/* Danh sách người được bảo hiểm */}
                <Col xs={24} style={{ marginBottom: 20 }}>
                  <hr />
                </Col>
                <Col xs={24}>
                  <div
                    style={{
                      fontSize: 18,
                      fontWeight: 600,
                      marginBottom: 10,
                    }}
                  >
                    Thông tin người được bảo hiểm
                  </div>
                </Col>
                <Col xs={24}>
                  <Row gutter={[10,0]} style={{ overflow: 'auto', overflowX: 'hidden', maxHeight: 470 }}>
                    {
                      insuredPersons?.map((item, index) => (
                        <>
                          <Col key={index} xl={6} xs={12}>
                            <p>
                              <div style={{ fontSize: 13 }}>Người được bảo hiểm {insuredPersons?.length > 1 ? index + 1 : ''}</div>
                              <div>
                                <b>{item?.insuredName}</b>
                              </div>
                            </p>
                            <p>
                              <div style={{ fontSize: 13 }}>Số điện thoại</div>
                              <div>
                                <b>{item?.insuredPhone}</b>
                              </div>
                            </p>
                          </Col>
                          <Col xl={6} xs={12}>
                            <p>
                              <div style={{ fontSize: 13 }}>Ngày sinh</div>
                              <div>
                                <b>
                                  {Helper._formatDayNoHour(
                                    item?.insuredDob
                                  )}
                                </b>
                              </div>
                            </p>
                            <p>
                              <div style={{ fontSize: 13 }}>Email</div>
                              <div
                                style={{ whiteSpace: "normal", wordWrap: "break-word" }}
                              >
                                <b>{item?.insuredEmail}</b>
                              </div>
                            </p>
                          </Col>
                          <Col xl={6} xs={12}>
                            <p>
                              <div style={{ fontSize: 13 }}>Giới tính</div>
                              <div>
                                <b>
                                  {+item?.insuredGender === 1
                                    ? "Nam"
                                    : "Nữ"}
                                </b>
                              </div>
                            </p>
                            <p>
                              <div style={{ fontSize: 13 }}>Địa chỉ</div>
                              <div>
                                <b>{item?.insuredAddress}</b>
                              </div>
                            </p>
                          </Col>
                          <Col xl={6} xs={12}>
                            <p>
                              <div style={{ fontSize: 13 }}>CCCD/ Mã định danh</div>
                              <div>
                                <b>{item?.insuredPrivateId}</b>
                              </div>
                            </p>
                            <p>
                              <div style={{ fontSize: 13 }}>
                                Mối quan hệ với chủ hợp đồng
                              </div>
                              <div>
                                <b>
                                  {item?.relationshipWithBuyerName || "Bản thân"}
                                </b>
                              </div>
                            </p>
                          </Col>
                          <Col
                            style={{ overflowX: "auto" }}
                            xs={24}
                            xl={24}
                          >
                            <div onClick={() => toggleSelection(index)} style={{ cursor: 'pointer', padding: "5px 10px", background: '#F8F8F8', display: 'flex', alignItems: 'center', justifyContent: 'space-between', width:'100%'}}>
                              <div style={{ color: '#3961A0' }}><b>Quyền lợi bảo hiểm</b></div>
                              <div>{!selectedIds.includes(index) ? <DownOutlined /> : <UpOutlined />}</div>
                            </div>
                            <table
                              style={{ width: "100%", display: selectedIds.includes(index) ? '' : 'none' }}
                              className="table-cuctom"
                            >
                              <tr>
                                <th>STT</th>
                                <th>Quyền lợi bảo hiểm</th>
                                <th>Tổng quyền lợi</th>
                                <th>Phí bảo hiểm</th>
                              </tr>
                              <tr>
                                <td
                                  style={{ textAlign: "left", background: "#F8F8F8" }}
                                  colSpan={4}
                                >
                                  Quyền lợi chính
                                </td>
                              </tr>
                              {item?.benefitsDetail?.map((item, i) => (
                                <tr style={{ display: item?.benefits?.kind === 'primary' ? '' : 'none' }}>
                                  <td>{i + 1}</td>
                                  <td style={{ textAlign: 'left' }}>{item?.benefits?.title}</td>
                                  <td>
                                    {Helper._formatMoney2(item?.benefits?.total_benefits || +item?.total_benefits_from_changed || +item?.total_benefits_from)}
                                  </td>
                                  <td>
                                    {Helper._formatMoney2(
                                      +item?.fees ||
                                      +item?.min_fees_changed || +item?.min_fees
                                    )}
                                  </td>
                                </tr>
                              ))}
                              <tr
                                style={{
                                  display:
                                    item?.benefitsDetail.filter(
                                      (item) => item?.benefits?.kind === "additional"
                                    ).length > 0
                                      ? ""
                                      : "none",
                                }}
                              >
                                <td
                                  style={{ textAlign: "left", background: "#F8F8F8" }}
                                  colSpan={4}
                                >
                                  Quyền lợi bổ sung
                                </td>
                              </tr>
                              {item?.benefitsDetail?.map((item, i) => (
                                <tr style={{ display: item?.benefits?.kind === 'additional' ? '' : 'none' }}>
                                  <td>{i + 1}</td>
                                  <td style={{ textAlign: 'left' }}>{item?.benefits?.title}</td>
                                  <td>
                                    {Helper._formatMoney2(item?.benefits?.total_benefits || +item?.total_benefits_from_changed || +item?.total_benefits_from)}
                                  </td>
                                  <td>
                                    {Helper._formatMoney2(
                                      +item?.fees ||
                                      +item?.min_fees_changed || +item?.min_fees
                                    )}
                                  </td>
                                </tr>
                              ))}
                              <tr
                              >
                                <td
                                  style={{ textAlign: "left", background: "#F8F8F8" }}
                                  colSpan={3}
                                >
                                  <b>Tổng phí bảo hiểm</b>
                                </td>
                                <td
                                  style={{ background: "#F8F8F8" }}
                                >
                                  <b>{Helper._formatMoney2(item?.fees)}</b>
                                </td>
                              </tr>
                            </table>
                          </Col>
                          <Col xs={24} style={{ marginBottom: 20, marginTop: 20, display: insuredPersons.length === (index + 1) ? 'none' : '' }}>
                            <hr />
                          </Col>
                        </>
                      ))
                    }
                  </Row>
                </Col>
              </Row>
            </div>
          </Row>
        </Col>
        <Col xl={7} xs={24}>
          <Row>
            <Col xs={24}>
              <div
                style={{
                  background: "#ffffff",
                  width: "100%",
                  padding: 20,
                  borderRadius: 2,
                  color: "#4B4B4B",
                }}
              >
                <Row gutter={[60, 0]}>
                  <Col xs={24}>
                    <div
                      style={{
                        fontSize: 18,
                        fontWeight: 600,
                      }}
                    >
                      Phí bảo hiểm
                    </div>
                  </Col>
                  <Col xl={24} xs={24} style={{ lineHeight: 2 }}>
                    <div style={css.divFlex}>
                      <div style={{ fontSize: 13 }}>Mã đơn hàng</div>
                      <div style={{ fontSize: 14 }}>
                        <b>{detail?.code}</b>
                      </div>
                    </div>
                    <div style={css.divFlex}>
                      <div style={{ fontSize: 13 }}>Ngày thanh toán</div>
                      <div style={{ fontSize: 14 }}>
                        <b>
                          {detail?.payment_date
                            ? Helper._formatDay(detail?.payment_date)
                            : "N/A"}
                        </b>
                      </div>
                    </div>
                    <div style={css.divFlex}>
                      <div style={{ fontSize: 13 }}>Tổng phí bảo hiểm</div>
                      <div style={{ fontSize: 14 }}>
                        <b>{Helper._formatMoney2(detail?.fees)} VNĐ</b>
                      </div>
                    </div>
                    <div style={css.divFlex}>
                      <div style={{ fontSize: 13 }}>Khuyến mãi</div>
                      <div style={{ fontSize: 14 }}>
                        <b>
                          -{Helper._formatMoney2(detail?.discount_amount)} VNĐ
                        </b>
                      </div>
                    </div>
                    <div style={css.divFlex}>
                      <div style={{ fontSize: 13 }}>Thuế VAT</div>
                      <div style={{ fontSize: 14 }}>
                        <b>{Helper._formatMoney2(+detail?.vat)} VNĐ</b>
                      </div>
                    </div>
                    <hr />
                    <div style={css.divFlex}>
                      <div style={{ fontSize: 13 }}>Tổng phí</div>
                      <div style={{ fontSize: 14 }}>
                        <b>{Helper._formatMoney2(+detail?.fees_payment)} VNĐ</b>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <div
                style={{
                  background: "#ffffff",
                  width: "100%",
                  padding: 20,
                  borderRadius: 2,
                  color: "#4B4B4B",
                  marginTop: 15,
                }}
              >
                <Row gutter={[60, 0]}>
                  <Col xs={24}>
                    <div
                      style={{
                        fontSize: 18,
                        fontWeight: 600,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      Giấy chứng nhận BH{" "}
                      <span style={{ fontWeight: 400 }}>
                        {renderStatus(detail?.status, "detail")}
                      </span>
                    </div>
                    {
                      ((detail?.status === 4 && detail?.cancellation_reason) || (detail?.status === 11 && detail?.rejected_reason)) &&
                      <div style={{ marginTop: 5 }}>
                        <div>{detail?.cancellation_reason ? 'Lý do huỷ đơn' : 'Lý do từ chối'}</div>
                        {
                          detail?.status === 4 &&
                          <div><i>{detail?.cancellation_reason}</i></div>
                        }
                        {
                          detail?.status === 11 &&
                          <div><i>{detail?.rejected_reason}</i></div>
                        }
                      </div>
                    }
                  </Col>
                  <Col xl={24} xs={24}>
                    <Form
                      layout={"vertical"}
                      ref={formRef}
                      scrollToFirstError
                      form={form}
                      onFinish={onFinish}
                    >
                      <>
                        {(detail?.status === 10 || detail?.status === 8 || detail?.status === 3) && (
                          <Form.Item
                            className="username"
                            style={{ marginBottom: 10, marginTop: 10 }}
                            label={
                              <span style={{ fontWeight: 400 }}>
                                Nhập số GCN
                              </span>
                            }
                            name="cernum"
                            rules={[
                              {
                                required: true,
                                message: "Vui lòng nhập đầy đủ thông tin.",
                              },
                            ]}
                          >
                            <Input
                              disabled={checkAccountGC || detail?.status === 3 ? true : checkAccountGC}
                              placeholder="Số GCN"
                            />
                          </Form.Item>
                        )}
                        {!detail?.cert_link && (detail?.status === 10 || detail?.status === 8) && (
                          <div style={{ marginTop: 10, position: "relative" }}>
                            <div>File giấy chứng nhận</div>
                            <div style={{ marginTop: -5 }}>
                              <Form.Item
                                className="username"
                                name="cer_link"
                                rules={[
                                  {
                                    required: true,
                                    message: "Vui lòng tải file lên.",
                                  },
                                ]}
                              >
                                <Input
                                  style={{
                                    display: "none",
                                  }}
                                />
                              </Form.Item>
                            </div>
                            <div
                              onClick={() => {
                                if (!checkAccountGC) {
                                  importInputRef.current.click();
                                }
                              }}
                              style={{
                                textDecoration: "underline",
                                color: "#0B7CE4",
                                cursor: checkAccountGC ? "no-drop" : "pointer",
                                marginTop: 5,
                                zIndex: 999999999,
                                position: "absolute",
                                top: 25,
                              }}
                            >
                              <b>Tải file lên</b>
                            </div>
                          </div>
                        )}
                        {(detail?.cert_link || link) && (
                          <>
                            <div style={{ marginTop: 15 }}>
                              File giấy chứng nhận
                            </div>
                            <div
                              style={{
                                padding: "0px 0px 10px 0px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                borderBottom: "1px solid #ECECEC",
                                width: "100%",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                <div>
                                  <img
                                    src={Ifile}
                                    style={{ width: 15, marginRight: 5 }}
                                  />
                                  <a target="_blank" href={detail?.cert_link}>
                                    Giấy chứng nhận.pdf
                                  </a>
                                </div>
                                {!detail?.cert_link && (
                                  <img
                                    onClick={() => {
                                      setLink("");
                                      form.resetFields(["cer_link"]);
                                    }}
                                    src={IXoa}
                                    width={18}
                                    style={{ cursor: "pointer" }}
                                  />
                                )}
                              </div>
                            </div>
                          </>
                        )}
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {(detail?.status === 10 || detail?.status === 8) &&
                            !detail?.cert_link &&
                            !checkAccountGC && (
                              <Button
                                htmlType="submit"
                                loading={loading}
                                disabled={detail?.cert_link ? true : false}
                                style={{
                                  background: detail?.cert_link
                                    ? "#A0A0A0"
                                    : "#0B7CE4",
                                  color: "#ffffff",
                                  minWidth: "90px",
                                  textAlign: "center",
                                  borderRadius: 6,
                                  border: detail?.cert_link
                                    ? "1px solid #A0A0A0"
                                    : "1px solid #0B7CE4",
                                  height: 40,
                                  marginTop: 10,
                                  marginRight: 10,
                                }}
                              >
                                <b>Cập nhật</b>
                              </Button>
                            )}
                          {((detail?.status === 8 || detail?.status === 10) && !checkAccountGC) && (
                            <Button
                              // htmlType="submit"
                              onClick={() => setOpenCancelOrder(true)}
                              loading={loading}
                              // disabled={detail?.status !== 8 ? true : false}
                              style={{
                                background: " #FFFFFF",
                                color: "#A0A0A0",
                                minWidth: "90px",
                                textAlign: "center",
                                borderRadius: 6,
                                border: "1px solid #A0A0A0",
                                height: 40,
                                marginTop: 10,
                              }}
                            >
                              <b>{detail?.status === 8 ? 'Từ chối' : 'Huỷ đơn'}</b>
                            </Button>
                          )}
                        </div>
                      </>
                    </Form>
                  </Col>
                </Row>
              </div>
              <div
                style={{
                  background: "#ffffff",
                  width: "100%",
                  padding: 20,
                  borderRadius: 2,
                  color: "#4B4B4B",
                  marginTop: 15,
                  display: detail?.request_link ? "" : "none",
                }}
              >
                <Row gutter={[60, 0]}>
                  <Col xs={24}>
                    <div
                      style={{
                        fontSize: 18,
                        fontWeight: 600,
                      }}
                    >
                      Giấy yêu cầu bảo hiểm
                    </div>
                  </Col>
                  <Col xl={24} xs={24}>
                    <div style={{ marginTop: 10 }}>
                      <div
                        style={{
                          textDecoration: "underline",
                          color: "#0B7CE4",
                          cursor: "pointer",
                          fontWeight: 600,
                        }}
                      >
                        <a target="_blank" href={detail?.request_link}>
                          Tải file
                        </a>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              {
                ((detail?.status === 4 || detail?.status === 11) && checkAccountGC) &&
                <div
                  style={{
                    background: "#ffffff",
                    width: "100%",
                    padding: 20,
                    borderRadius: 2,
                    color: "#4B4B4B",
                    marginTop: 15,
                  }}
                >
                  <Row gutter={[60, 0]}>
                    <Col xs={24}>
                      <div
                        style={{
                          fontSize: 18,
                          fontWeight: 600,
                        }}
                      >
                        Ghi chú CSKH
                      </div>
                    </Col>
                    <Col xl={24} xs={24}>
                      <Form
                        layout={"vertical"}
                        ref={formRef}
                        scrollToFirstError
                        form={form}
                        onFinish={onFinish2}
                      >
                        <Form.Item
                          className="username"
                          style={{ marginBottom: 0 }}
                          // label={<span style={{ fontWeight: 400 }}></span>}
                          name="note"
                          rules={[
                            {
                              required: true,
                              message: "Vui lòng nhập đầy đủ thông tin.",
                            },
                          ]}
                        >
                          <Input.TextArea
                            allowClear
                            autoSize={{ minRows: 8, maxRows: 8 }}
                            placeholder="CSKH nhập nội dung"
                          />
                        </Form.Item>
                        <Button
                          htmlType="submit"
                          loading={loading}
                          style={{
                            background: "#3961A0",
                            color: "#ffffff",
                            minWidth: "90px",
                            textAlign: "center",
                            borderRadius: 6,
                            border: "1px solid #3961A0",
                            height: 40,
                            marginTop: 10,
                            marginRight: 10,
                          }}
                        >
                          <b>Cập nhật</b>
                        </Button>
                      </Form>
                    </Col>
                  </Row>
                </div>
              }
            </Col>
          </Row>
        </Col>
      </Row>
      <div style={{ width: "100%", marginBottom: 20 }}>
        <Row gutter={[60, 0]}>
          <Col xs={24} xl={24}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: 20,
                width: "100%",
              }}
            >
              <Button
                onClick={() => setPage(1)}
                style={{
                  background: "#ffffff",
                  color: "#0B7CE4",
                  width: "90px",
                  textAlign: "center",
                  borderRadius: 6,
                  border: "1px solid #0B7CE4",
                  height: 40,
                }}
              >
                <b>Quay lại</b>
              </Button>
            </div>
          </Col>
        </Row>
      </div>
      <input
        ref={importInputRef}
        type="file"
        accept="application/pdf"
        onClick={(e) => {
          e.target.value = "";
        }}
        onChange={(e) => handleImportFile(e.target.files[0])}
        style={{
          display: "none",
        }}
      />
    </>
  );
}

const css = {
  suffix: {
    height: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,
    position: "absolute",
    right: 15,
    top: 37,
    cursor: "pointer",
    fontSize: 14,
    color: "#A0A0A0",
  },
  themtuoi: {
    padding: "7px 11px",
    border: "1px solid #3961A0",
    width: "fit-content",
    color: "#3961A0",
    borderRadius: 5,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  divFlex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
};
